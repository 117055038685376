'use strict';
(function ($) {

    $.fn.tree = function (action) {

        var defaults = {
            defaultLevel: 0,
            defaultAction: "collapse",
            nodeSelector: ".tree__node",
            nodeClass: "tree__node",
            nodeExpandedClass: "tree__node--expanded",
            nodeCollapsedClass: "tree__node--collapsed",
            nodeHiddenClass: "tree__node--hidden",
            nodeLeafClass: "tree__node--leaf",
            forceToggle: false
        }, options = {}, cb = {
            init: function () {
                $.utils.trace("tree: init.call");
                var _$tree = this;
                _$tree.find(options.nodeSelector)
                    .map(function () {
                        $(this).addClass(options.nodeClass);
                        if (
                            options.defaultLevel > 0
                            && $(this).data("level") == options.defaultLevel
                            && (
                                options.forceToggle === true // todo: move to toggle func
                                || !$(this).hasClass(options.nodeCollapsedClass)
                            )
                        ) {
                            cb.toggleRecursive.call(this, _$tree, "collapse", true);
                        }
                    });
                _$tree.on("click", options.nodeSelector, function (e) {
                    cb.toggle.call(this, e);
                }).addClass("tree tree--initialized");
            },
            toggle: function (e) {
                $.utils.trace("tree: toggle.call on #" + $(this).attr("id"));
                var _class = options.defaultAction == "collapse"
                    ? options.nodeCollapsedClass
                    : options.nodeExpandedClass;
                var _action = $(this).hasClass(_class) ? "expand" : "collapse";
                cb.toggleRecursive.call(this, $(e.delegateTarget), _action, false);
            },
            toggleRecursive: function (_$tree, _action, _recursive) {
                $.utils.trace("tree: " + _action + ".call on #" + $(this).attr("id"));
                _action = _action || options.defaultAction;
                var _children = _$tree.find("[data-parent=\"#" + $(this).attr("id") + "\"]");
                if (_children.length > 0) {
                    _children.map(function () {
                        if (_recursive === true || _action == "collapse") {
                            cb.toggleRecursive.call(this, _$tree, _action, true)
                        }
                        _action == options.defaultAction
                            ? $(this).addClass(options.nodeHiddenClass)
                            : $(this).removeClass(options.nodeHiddenClass);
                    });
                    $(this)
                        .removeClass(_action == options.defaultAction
                            ? options.nodeExpandedClass
                            : options.nodeCollapsedClass)
                        .addClass(_action == options.defaultAction
                            ? options.nodeCollapsedClass
                            : options.nodeExpandedClass);
                } else {
                    $(this).addClass(options.nodeLeafClass);
                }
            }
        };

        var data = this.data("tree");
        if (typeof action == "object") {
            $.extend(options, defaults, action, data);
            action = 'init';
        } else {
            $.extend(options, defaults, data);
            action = action || 'init';
        }

        if (cb[action] !== 'undefined' && typeof cb[action] == "function") {
            var _$tree = this;
            cb[action].call(_$tree);
            setTimeout(function () {
                _$tree.trigger("jquery.tree." + action);
            }, 0);
        } else {
            $.utils.trace("'" + action + "' action is undefined");
        }

        return this;
    };

})(jQuery);
