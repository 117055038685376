'use strict';
(function ($) {
    $.utils = {
        trace: function (m) {
            if (window["DEBUG"] !== 'undefined' && window["DEBUG"] === true) {
                console.log("[" + Date.now() + "] " + m);
            }
        },
        log: function (m) {
            console.log("[" + Date.now() + "] " + m);
        },
        info: function (m) {
            console.info("[" + Date.now() + "] " + m);
        }
    };
})(jQuery);
