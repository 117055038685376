$(document).ready(function() {

    /**
     * Глобальные переменные
     */
    var WIN = $(window),
        DOC = $(document),
        HTML = $('html');



    $('.toggler').click(function(){
        $('.toggled').toggle();
    });

    $('.toggle-link').on('click',function() {
        $(this).next().toggle();
    });

    $('.hide-on-click').click(function(){
        $(this).slideUp();
    });

    $(document).on('click', '.toggle-element', function(){
        var t = $($(this).data('target'));

        if ( t.hasClass('hide') )
            t.removeClass('hide');
        else
            t.addClass('hide');
    });


    /**
     * Функция фиксации панели с информацией о игроке
     * при скролле в карточке игрока
     */
    function playerPanelFixed(){
        let nav = $("#panelContent");
        if(nav.length){
            let panel = $('.player__card'),
                panel_width = panel.width(),
                h = nav.offset().top - 50;

            if (panel.hasClass('panel--fixed')) {
                nav.css('width', panel_width + 'px');
            } else {
                nav.css('width', 'auto');
            }

            WIN.on('scroll', function() {
                if(WIN.scrollTop() > h) {
                    panel.addClass("panel--fixed");
                    nav.css('width', $('.player__card').width() + 'px');
                }
                else{
                    panel.removeClass("panel--fixed");
                }
            });
        }
    }
    playerPanelFixed();

    /**
     * События на ресайз окна
     */
    WIN.on('resize', function() {
        playerPanelFixed();
    });

    // /**
    //  * Очиста инпутов логина\пароля на странице авторизации
    //  */
    // setTimeout(function() {
    //     $('.page-signin input[type=text], .page-signin input[type=password]').val('');
    // }, 100);


    /**
     * Страница со списком игр
     */
    let //games_counter_val = $('.sorter-field:first-of-type').val(),
        games_id_input = '#game-grid .table tbody tr input.sorter-field';
    // инициализация сортировки через drag & drop
    $( ".sortable" ).sortable({
        handle: '.fa-arrows',
        stop: function( event, ui ) {
            games_counter();
            games_update();
        }
    });

    // сортировка порядкового номера у игр при инициализации и перетягивании
    function games_counter(){
        // let games_counter = games_counter_val;
        // console.log(games_counter);
        $('#game-grid .table tbody tr').each(function(){
            var inp = $(this).find('input.sorter-field');
            $(inp).val($(inp).data('order'));
            //games_counter++;
        });
    }

    // нажатие кнопки обновления сортировки
    function games_update(){
        $('.sorterUpdate').trigger('click');
    }

    // $(games_id_input).keypress(function(e){
    //     if (e.which == 13) {
    //         games_update();
    //     }
    // });

    // проверка изменения ID игры в инпуте
    DOC.on('change', games_id_input, function(){
        if($(this).val() != $(this).attr('data-order')){
            $(this).siblings('.table__save-msg').show();
        }
        else{
            $(this).siblings('.table__save-msg').hide();
        }
    });

    games_counter();


    /**
     * Адаптация размера текста в логотипе
     */
    function logoFontSize(){
        let logo = $('.logo a'),
            textLength = logo.text().length;

        if(textLength > 60) {
            logo.css({'font-size': '11px'});
        }
        else if(textLength > 55) {
            logo.css({'font-size': '12px', "line-height": '1.25'});
        }
        else if(textLength > 48) {
            logo.css({'font-size': '13px', "line-height": '1.25'});
        }
        else if(textLength > 35) {
            logo.css({'font-size': '14px', "line-height": '1.25'});
        }
        else if(textLength > 28) {
            logo.css('font-size', '16px');
        }
        else if(textLength > 16) {
            logo.css('font-size', '18px');
        }
        else if(textLength > 13) {
            logo.css('font-size', '22px');
        }
        else if(textLength <= 12) {
            logo.css('font-size', '24px');
        }

        logo.attr('data-count', textLength);
    }

    logoFontSize();


    /**
     * Активация\деактивация кнопки блокировки игроков в зависимости от выбра чекбоксов
     * https://jira.platform.live/browse/CORE-985
     */
    let blocked_btn = $('.blocked-button input'),
        blocked_checkbox_container = $('#blocked-players-grid');

    blocked_btn.attr('disabled', 'disabled');

    $(document).on('change', '#blocked-players-grid input[type=checkbox]', function () {
        if(blocked_checkbox_container.find('input[type=checkbox]:checked').length > 1){
            blocked_btn.removeAttr('disabled');
        }
        else{
            blocked_btn.attr('disabled', 'disabled');
        }
    });


    /**
     * Аккордеон
     * По умолчанию открываем блок с контентом
     **/
    $('.accordion--opened').find('.accordion__content').show();
    DOC.on('click', '[data-accordion] .accordion__title', function(){
        var container = $(this).parents('[data-accordion]'),
            item = $(this).parents('.accordion__item');

        if(item.hasClass('accordion--opened')){
            item.toggleClass('accordion--opened');
            item.find('.accordion__content').slideToggle();
        }
        else{
            container.find('.accordion--opened').removeClass('accordion--opened').find('.accordion__content').slideUp();
            item.toggleClass('accordion--opened');
            item.find('.accordion__content').slideToggle();
        }
    });


    /**
     * Табы
     * */
    $('ul.tabs-list').on('click', 'li:not(.active)', function() {
        $(this).addClass('active').siblings().removeClass('active').parents('.tabs').find('.tabs__content').removeClass('active').eq($(this).index()).addClass('active');
        //инициализируем слайдеры лимитов
        rangeSlider();
    });


    /**
     * Переключатель для скрытия выбранных лимитов
     * */
    DOC.on('click', '[data-limit-filter]', function(){
        if($(this).siblings('input[type=checkbox]:checked').length != 0){
            $(this).parents('.active').find('.limit-set__item').show();
        }
        else{
            $(this).parents('.active').find('.limit-set__item').toggle();
            $(this).parents('.active').find('.limit-set--active').show();
        }
    });


    /**
     * Переключатель для активации\деактивации конкретного лимита
     * */
    DOC.on('click', '.limit-set__item input[type=checkbox]', function(){
        if ($(this).is(':checked') != true) {

            let parent = $(this).parents('.limit-set__item');
            parent.removeClass('limit-set--active')
            let before = parent.find('.limit-set__right [type="range"]');

            if (before.length > 0) {
                var dataCurrent = before.data('current');
                let input = $(this).parents('.limit-set__item').find('[data-limit-value]');
                input.val(dataCurrent);
                input.trigger('change');
            }
        }
        else{
            $(this).parents('.limit-set__item').addClass('limit-set--active');
        }
    });


    /**
     * Рассчёт лимита (свободного для распределения)
     * */
    function limits(){
        let limits_sum = 0,
            main_limit = parseFloat($('.tabs__content.active [data-main-limit]').html()),
            limits_difference;

        $('.tabs__content.active .limit-set__item').each(function(){
            let limits_value = $(this).find('span').html();
            limits_sum += parseFloat(limits_value);
        });

        limits_difference = main_limit - limits_sum;
        limits_difference = limits_difference.toFixed(2);

        return limits_difference;
    }

    /**
     * Обновления значений слайдеров выбора лимитов
     * */
    function updateInputs(){
        let active_tab = $('.tabs__content.active'),
            free_limit = parseFloat(limits()),
            new_limit_sum;

        active_tab.find('[data-rangeslider]').each(function(){
            new_limit_sum = parseFloat($(this).parents('.limit-set__item').find('.limit-set__value span').html()) + free_limit;
            $(this).data("ionRangeSlider").update({
                from_max: new_limit_sum
            });
        });
    }

    /**
     * Инициадизация слайдера выбора лимита
     * */
    function rangeSlider(){
        if($('.irs').length){
            $('[data-rangeslider].irs-hidden-input').each(function(){
                $(this).data("ionRangeSlider").destroy();
            });
        }

        let active_tab = $('.tabs__content.active'),
            unlimited,
            unlimited_attr = active_tab.find('[data-main-limit-unlimited]'),
            block;

        if (unlimited_attr.length > 0){
            unlimited = 1;
            active_tab.find('.limit-set').addClass('limit-set--unlimited');
        }

        active_tab.find('[data-rangeslider]').each(function(){

            block = unlimited === 1;

            $(this).ionRangeSlider({
                step: 0.01,
                hide_min_max: true,
                block: block,
                onFinish: updateInputs,
            });

            $(this).parents('.limit-set__item').find('.limit-set__value span').html($(this).data("from"));

            $(this).on('change', function(){
                let parents = $(this).parents('.limit-set__item');
                let val = parseFloat($(this).data("from"));
                if (parents.find('.errorMessage.error').length === 0) {
                    parents.find('.limit-set__value span').html(val);
                    parents.find('[data-limit-value]').val(val);
                }
            });
        });
    }

    rangeSlider();

    let current_input_val;

    $('[data-limit-value]').on("focusin", function () {
        current_input_val = $(this).val();
        $(this).val('');
    });
    $('[data-limit-value]').on("focusout", function () {
        if($(this).val() === ''){
            $(this).val(current_input_val);
        }
    });


    $('[data-limit-value]').on("change", function () {
        if($('.tabs__content.active').find('[data-main-limit-unlimited]').length > 0){

        }
        else{
            let free_limit = parseFloat(limits()),
                val = parseFloat($(this).val()),
                min = 0,
                max = parseFloat($(this).parents('.limit-set__item').find('.limit-set__value span').html()) + free_limit;

            if (val < min) {
                val = min;
                writeError($(this), 'tooLow');
            }
            else if (val > max) {
                val = max;
                writeError($(this), 'tooHigh');
            }

            $(this).parents('.limit-set__content').find('[data-rangeslider]').each(function(){
                $(this).data("ionRangeSlider").update({
                    from: val
                });
            });
        }

        updateInputs();
    });
});

/**
 *  Toggle form for adding comment in players profile
 */
function open_comment_form(elem) {
        $("div[comment_form_id=" +  $(elem).attr('post_id') + "]").toggle();
}

function deleteWallElement(url) {
        if (! confirm("Are you sure ?"))
                return;

        $.ajax({
                url: url,
                success:function(data) {
                        $('.posts').html(data);
                }
        });
}
