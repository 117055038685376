window.randomChars = {
    upperChars : "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    lowerChars : "abcdefghijklmnopqrstuvwxyz",
    digits : "0123456789",
    specialChars: '`~!+@#№%^&*()-_=/\\{}[]"\'.,|:;',

    generateAll: function (chars, length) {
        return this.generate(chars, length, this.upperChars + this.lowerChars + this.digits + this.specialChars);
    },

    generateDigits: function (chars, length) {
        return this.generate(chars, length, this.digits);
    },

    generate: function (chars, length, fromChars) {
        for (var i = 1; i < length; i++) {
            chars.push(this.generateChar(fromChars));
        }

        chars.sort(function () {
            return Math.random() - 0.5;
        });
        return chars.join("");
    },

    generateChar: function (chars) {
        return chars.charAt(Math.floor(Math.random() * chars.length));
    }
};

window.generateRandomDigits = function (length)
{
    var chars = [];
    chars.push(randomChars.generateChar(randomChars.digits));

    return randomChars.generateDigits(chars, length);
};

window.generateRandomString = function(length, useDigits, useCapital, useSpecial)
{
    var chars = [];

    chars.push(randomChars.generateChar(randomChars.lowerChars));

    if (useDigits) {
        length--;
        chars.push(randomChars.generateChar(randomChars.digits));
    }

    if (useCapital) {
        length--;
        chars.push(randomChars.generateChar(randomChars.upperChars));
    }

    if (useSpecial) {
        length--;
        chars.push(randomChars.generateChar(randomChars.specialChars));
    }

    return randomChars.generateAll(chars, length);
};

$(function(){
	$('body').on('click', '.grid-view [data-js="gridAjaxButton"]', function (e) {
		e.preventDefault();
		var callback = null;
		if (
			typeof $(this).data('callback') !== 'undefined'
			&& typeof window[$(this).data('callback')] === 'function'
		) {
			callback = window[$(this).data('callback')];
		}
		$.ajax({
			url: $(this).attr('href'),
			success: function(data) {
				if (callback !== null) {
					callback(data);
				}
			},
		});
	});

	//$(".table").stacktable();

	$('.checkboxer').on('click', function(){
		var checkboxes = $($(this).data('target')).find(':checkbox');

		if($(this).is(':checked')) {
			checkboxes.attr('checked', 'checked');
		} else {
			checkboxes.removeAttr('checked');
		}
	});

	// ================= Node tree =================

	var nodeTreeContainer = $('#node-tree-container');

	$(document).keyup(function(e) {
		if (e.keyCode == 27) {
			nodeTreeContainer.hide();
		}
	});

	var treeContainer = $('#node-tree');
	var treeBreadCrumbs = $('.fancytree-breadcrumbs');


	// Clicking node breadcrumbs
	treeBreadCrumbs.on('click', function () {
		var _t = $(this);

		var nodeActiveId = treeBreadCrumbs.last('li').data('node-id');

		if (nodeTreeContainer.is(':visible')) {
			nodeTreeContainer.hide();
		}
		else if (nodeActiveId) {
			nodeTreeContainer.show();

			treeContainer.fancytree("getTree").activateKey('id' + nodeActiveId);
			$('#tree-search').focus();
		}

	});


	// Initialize fancytree
	treeContainer.fancytree({
		extensions: ["filter"],
		quicksearch: true,
		filter: {
			mode: "hide"
		},
		click: function(event, data) {
			if (data.targetType == 'title') {
				var node = data.node;
				if( node.data.href ){
					location.href = node.data.href;
				}
			}
		}
	});


	var tree = treeContainer.fancytree("getTree");

	// Fancytree search
	$("#tree-search").keyup(function(e){
		var match = $(this).val();

		if(e && e.which === $.ui.keyCode.ESCAPE || $.trim(match) === ""){
			tree.clearFilter();
			return;
		}

		tree.filterNodes(match, false);

		treeContainer.fancytree("getRootNode").visit(function(node){
			node.setExpanded(true);
		});
	}).focus();



	// ================= Stuff on load =================
	$('.active').addClass('activeMenu')

	function getOpenLi() {
		if (localStorage.getItem('openMenu') !== null) {
			var openLi = localStorage.getItem('openMenu');
			$(`[id = ${openLi}]`).addClass('in');
		}
	}

	function getMenuScrollPosition() {
		var scrollPos = localStorage.getItem('scrollMenu')||0;
		$('.slimScrollDiv:last').scrollTop(scrollPos);
	}

	// Enable bootstrap tooltips
	$('[data-toggle="tooltip"]').tooltip();

	// Enable bootstrap popovers
	$('[data-toggle="popover"]').popover();


	// Add caret to elements with submenu
	$('.main-side-menu').find('li>ul').after('<i class="fa fa-caret-right icon-has-ul"></i>');

	// If menu is collapsed to icon
	if ( localStorage.getItem('bodyNavMin') )
	{
		$('body').addClass('nav-min');
	}
	else
	{
		// Leave menu open
		var activeLi = $('ul.main-side-menu li.active');
		activeLi.find('> a').removeClass('collapsed');
		activeLi.find('> ul').removeClass('collapse').addClass('collapse in');
	}

	if ( localStorage.getItem('byToggleMin') )
	{
		$('body').addClass('byToggleMin');
		getOpenLi();
	}

	$.each($('.active'), function () {
		$(this).addClass('openLi');
		$(this).find('ul').addClass('in');
	});

	$.each($('ul.in'), function() {
		if (!$(this).parent('li').hasClass('active')) {
			$(this).removeClass('in');
		}
	});

	$('.btn').on('click', function(e) {
	});

	$('.slimScrollDiv:last').scroll(function() {
		localStorage.setItem('scrollMenu', $('.slimScrollDiv:last').scrollTop());
	});

	$( document ).ready(function() {
		getMenuScrollPosition();
	});

	// ================= Menu behavior =================

	// Prevent setting classes on menu elements in minimized mode
	$('.main-side-menu > li > a').on('click', function(e){
		if($(this).attr('href') === '#'){
			e.preventDefault();
		}
		if ( $('body').hasClass('nav-min') )
		{
			var attr = $(this).attr('data-toggle');

			if (typeof attr !== 'undefined' && attr !== false)
				return false;
		}
	});

	$('.main-side-menu > li').mouseenter(function () {

		if ($('body').hasClass('nav-min')) {
			$('body').removeClass('nav-min');
		}
		getMenuScrollPosition();
		playerPanelFixed();
	});

	$('aside').mouseleave(function () {
		if ($('body').hasClass('byToggleMin')) {
			$('body').addClass('nav-min');
		} else {
			$('body').removeClass('side-menu-fix');
		}
		playerPanelFixed();
	});

	// Collapse other menu elements on click to another
	$('.main-side-menu > li').on('click', function(e){

		if ( ! $(this).hasClass('active') )
		{
			var currentActiveLi = $('ul.main-side-menu li.active');
			currentActiveLi.removeClass('active');
			currentActiveLi.find('> a').addClass('collapsed');
			currentActiveLi.find('> ul').removeClass('collapse in').addClass('collapse');

			$(this).addClass('active');
		}
	});


	$('#dashboard').click(function (e) {
		e.preventDefault();

		$.each($('.openLi'), function () {
			$(this).removeClass('openLi');
		});

		window.location.replace($('#dashboard ').attr('href'));

	});

	function checkMenu () {
		if ($(window).width() < 1232){
			$("body").addClass("nav-min");
		}
	}

	checkMenu();

	$(window).resize(function(){
		checkMenu();
	});

	function playerPanelFixed(){
		let nav = $("#panelContent");
		if(nav.length){
			let panel = $('.player__card'),
				panel_width = panel.width();

			if (panel.hasClass('panel--fixed')) {
				nav.css('width', panel_width + 'px');
			} else {
				nav.css('width', 'auto');
			}
		}
	}

	// Collapse side menu to icons
	$('.toggle-min').on('click', function(event){
		event.preventDefault();
		var body = $('body');
		body.toggleClass('byToggleMin');

		if (body.hasClass('byToggleMin')) {
			localStorage.setItem('byToggleMin', true);
		} else {
			localStorage.removeItem('byToggleMin');
		}

		//setOpenItems();
		getOpenLi();

		getMenuScrollPosition();

		if ( body.hasClass('nav-min') )
		{
			body.removeClass('nav-min');
			localStorage.removeItem('bodyNavMin');
		}
		else
		{
			body.addClass('nav-min');
			localStorage.setItem('bodyNavMin', true);

			// Close open menu
		}

		playerPanelFixed();
	});

	//Close menu
	$('#nav-container').on('swipeleft', function(){
		$('body').addClass('nav-min');
	});

	$('body').on('click', function(e){
		if ($(e.target).closest('#nav-container').length === 0 && $(e.target).closest('.menu-button, .toggle-min').length === 0 && $(window).width() < 1231){
			$(this).addClass('nav-min');
		}
	});

	//TABLE STATUS======================

	var statusList = $("[data-status]").find("option");

	function updateStatus (table){
		if($(window).width() < 1232){
			$(table).parent().append('<div class="toggle-status">Status</div>');
		}

		if ($(".toggle-status")){
			var num = 1;

			$(".toggle-status").click(function(){
				var currentClass = $(statusList[num]).text().toLowerCase();

				$(this).addClass(currentClass).text(currentClass);
				$(this).removeClass($(statusList[num - 1]).text().toLowerCase());

				if (num === statusList.length){
					num = 1;
					$(this).text("status");
				} else {
					num += 1;
				}
			});
		}
	}

	updateStatus(".table-bonus");
	// ----------------- Menu behavior -----------------



	// ================= Firefox fix for switchers =================

	$('label.switch input, label.ui-checkbox input').on('click', function () {
		return false;
	});

	$('label.switch, label.ui-checkbox').on('click', function(){
		var checkBox = $(this).find('input[type="checkbox"]');

		checkBox.prop('checked', !checkBox.prop('checked'));
	});

	// ----------------- Firefox fix for switchers -----------------
	if (window.navigator.userAgent.indexOf('Firefox/') >= 0) {
		$("html").addClass("mozilla");
	}
	if (window.navigator.userAgent.indexOf('Edge/') >= 0) {
		$("html").addClass("edge");
	}
	if (window.navigator.userAgent.indexOf('Trident/') >= 0) {
		$("html").addClass("ie");
	}
	if (window.navigator.userAgent.indexOf('Chrome/') >= 0) {
		$("html").addClass("chrome");
	}	
	var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform),
		isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);		
	if (isMacLike) {
		$("html").addClass("apple");
	}
	if (isIOS) {
		$("html").addClass("ios");
	}

	$('.table-details').on('click', '.details-open', function(){
		var row = $(this).closest('tr');
		row.removeClass('closed').addClass('open');
		if (!row.hasClass('loaded')) {
			var url = $(this).data('url');
			$.get(url).done(function (data) {
				row.addClass('loaded');
				row.after('<tr class="details-row"><td colspan=1000 class="empty">' + data + '</td></tr><tr style="display:none"></tr>');
			});
		}
	}).on('click', '.details-close', function(){
		var row = $(this).closest('tr');
		row.removeClass('open').addClass('closed');
	});
	
});


// ---- datepicker langs -----
$.datepicker.regional['ru'] = {
        closeText: "Закрыть",
        prevText: "&#x3C;Пред",
        nextText: "След&#x3E;",
        currentText: "Сегодня",
        monthNames: [ "Январь","Февраль","Март","Апрель","Май","Июнь","Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь" ],
        monthNamesShort: [ "Янв","Фев","Мар","Апр","Май","Июн", "Июл","Авг","Сен","Окт","Ноя","Дек" ],
        dayNames: [ "воскресенье","понедельник","вторник","среда","четверг","пятница","суббота" ],
        dayNamesShort: [ "вск","пнд","втр","срд","чтв","птн","сбт" ],
        dayNamesMin: [ "Вс","Пн","Вт","Ср","Чт","Пт","Сб" ],
        weekHeader: "Нед",
        dateFormat: "dd.mm.yy",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ""
};
$.datepicker.regional['pl'] = {
        closeText: "Закрыть",
        prevText: "&#x3C;Пред",
        nextText: "След&#x3E;",
        currentText: "Сегодня",
        monthNames: [ "Styczeń","Luty","Marzec","Kwiecień","Maj","Czerwiec","Lipiec","Sierpień","Wrzesień","Październik","Listopad","Grudzień" ],
        monthNamesShort: [ "Янв","Фев","Мар","Апр","Май","Июн", "Июл","Авг","Сен","Окт","Ноя","Дек" ],
        dayNames: [ "воскресенье","понедельник","вторник","среда","четверг","пятница","суббота" ],
        dayNamesShort: [ "вск","пнд","втр","срд","чтв","птн","сбт" ],
        dayNamesMin: ["Ni", "Po", "Wt", "Sr", "Cz", "Pi", "So"],
        weekHeader: "Нед",
        dateFormat: "dd.mm.yy",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ""
};
// ---- /datepicker langs -----
